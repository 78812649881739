import api from '@/libs/axios'

export default {
  async create(payload) {
    const response = await api.post('/admin/learning-words', payload)
    return response
  },
  async update(payload) {
    let response = await api.put('/admin/learning-words', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/learning-words', {
      data: payload,
    })
    return response
  },
  async getList(params) {
    const response = await api.get('/admin/learning-words', { params })
    return response
  },
  async get(params) {
    let response = await api.get('/admin/learning-words/detail', {
      params,
    })
    return response.data.data.document || null
  },
  async getWords(params){
    const response = await api.get('/admin/learning-words/all', { params })
    return response
  },
  async getWordByIds(params){
    const response = await api.get('/admin/learning-words/word-by-ids', { params })
    return response.data.data.words || null
  }
}