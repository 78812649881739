<template>
  <div>
    <b-card class="text-center" v-if="word">
      <template slot="header">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            flex-grow-1
            w-100
          "
        >
          <div style="position: absolute; right: 5px">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="text-body align-middle"
                />
              </template>
              <b-dropdown-item @click.stop="updateWord">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click.stop="confirmDelete(word._id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <b-img
        width="100px"
        :src="word.image.value"
        v-if="word.image.value"
      />
      <b-img
        v-else
        :blank="true"
        blank-color="#ccc"
        alt="placeholder"
        height="172"
        width="100px"
      />
      <h3 class="mt-1 mb-0 text-truncate-2">{{ word.title }} ({{ word.word_type }})</h3>
    </b-card>
    <b-card v-else>
      <template slot="header">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            flex-grow-1
            w-100
          "
        >
          <div style="position: absolute; right: 5px">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="text-body align-middle"
                />
              </template>
              <b-dropdown-item @click.stop="confirmDelete(wordId)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <h3 class="mt-1 mb-0 text-truncate-2">Word removed from pool</h3>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    word: { type: Object, required: true },
    index: {type: Number, required: true}
  },
  methods: {
    confirmDelete() {
      this.$emit("confirmDelete", this.index);
    },
    updateWord() {
      this.$emit("updateWord",{word: this.word, index: this.index});
    },
  },
};
</script>
