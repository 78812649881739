<template>
  <div>
    <component
      :is="componentName"
      :component-data="component_data"
      :key="index"
      :options="options"
      @updated="$emit('updated')"
    />
  </div>
  
</template>

<script>
import Pronun from './Pronun/WordList.vue'
import Listening from './Listening/TopicCard.vue'
export default {
  name: "ExamComponents",
  components: {
    "part-type-1": Pronun,
    "part-type-2": Listening,
    "part-type-3": Listening,
  },
  props: {
    component_data: { type: Object, required: true },
    type: { type: Number, required: true},
    index: {type: String, required: true},
    options: {type: Object, default: () => {return {}}}
  },
  // data() {
  //   return {
  //     index: 1
  //   }
  // },
  computed: {
    componentName() {
      let name = `part-type-`+this.type;
      let localComponents = Object.keys(this.$options.components);
      return localComponents.includes(name) ? name : "empty-field";
    },
  },
};
</script>
