<template>
  <div>
    <b-row class="mb-1">
      <b-col class="mt-1" cols="4">
        <h5>Words</h5>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        class="col-lg-3 col-md-6 col-12"
        v-for="(word,index) in words_detail"
        :key="`${word}_${index}`"
      >
        <word-card
          :word="word"
          :index="index"
          @confirmDelete="confirmDelete"
          @updateWord="openUpdateModal"
          ref="word_card"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center mt-1" cols="12">
        <b-button variant="primary" @click="getWords" :disabled="words && words.length >= options.limit_words">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Select word from pool</span>
        </b-button>
        <b-button class="ml-2" variant="warning" @click="openCreateModal" :disabled="words && words.length >= options.limit_words">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Create new word</span>
        </b-button>
      </b-col>
    </b-row>
    <vue-select class="vue-select1 mt-2" name="select1"
      v-if="word_options && is_select_word"
      :options="word_options"
      v-model="selected_word" 
      label="label" :reduce="label => label.value" 
      @input="addSelectedWord"
      :searchable="true"
      placeholder="Find & select"
      style="background-color: white"
      @search="searchingWord"
    />
    <word-create-modal
      @addWord="addWord"
      ref="modalWord"
    />
  </div>
</template>

<script>
import WordCard from "./WordCard.vue";
import WordCreateModal from "./WordCreateModal";
import VueSelect from 'vue-select'
import service from './service'
export default {
  name: "word-field",
  components: {
    WordCard,
    WordCreateModal,
    VueSelect
  },
  props: {
    componentData: {type: Object, required: true},
    options: {type: Object, default: ()=>{return {}}}
  },
  data() {
    return {
      currentWord: { type: Object },
      words: this.componentData.words,
      is_select_word: false,
      word_options: [],
      selected_word: null,
      word_key: null,
      words_detail: [],
    };
  },
  created(){
    this.getWordsDetail();
  },
  methods: {
    addWord(word) {
      this.componentData.words.push(word);
      this.getWordsDetail();
    },
    openCreateModal(){
      this.$refs.modalWord.openCreateModal()
    },
    openUpdateModal(updateData){
      this.$refs.modalWord.openUpdateModal(updateData.word, updateData.index)
    },
    confirmDelete(index) {
      this.$bvModal
      .msgBoxConfirm("Are you sure ?", {
        title: "Please Confirm",
        size: "md",
        okVariant: "danger",
        okTitle: "Yes",
        cancelTitle: "No",
        cancelVariant: "outline-secondary",
        hideHeaderClose: true,
        centered: true,
      })
      .then((value) => {
        if (value === true) {
          this.deleteWord(index);
        }
      });
    },
    deleteWord(i){
      this.componentData.words.splice(i,1);
      this.words_detail.splice(i,1);
    },
    async getWords(){
      let query = {
        status: 1
      }
      if (this.word_key){
        query.title = { $regex: this.word_key, "$options": 'i' }
      }
      let response = await service.getList({
        query: query,
        limit: 50
      })
      if (response){
        this.word_options = [];
        response.data.data.list.forEach(word=>{
          this.word_options.push({
            label: word.title,
            value: word._id,
            key: word.title+'-'+word._id
          })
        })
      }
      this.is_select_word = true;
    },
    async getWordsDetail(){
      let word_ids = this.componentData.words;
      let word_detail_response = await service.getWordByIds({word_ids: JSON.stringify(word_ids)}); 
      this.words_detail = word_ids.map((word)=>{
        let data = word_detail_response.find((item)=>{
          return item._id == word
        })
        return data
      })
    },
    async addSelectedWord(){
      this.componentData.words.push(this.selected_word);
      let word_detail = await service.get({ id: this.selected_word})
      this.selected_word = null;
      this.words_detail.push(word_detail)
    },
    searchingWord(word_key){
      this.word_key = word_key;
      this.getWords();
    }
  },
};
</script>
