export default {
  SINGLE_CHOICE: 1,
  ARRANGE_SENTENCES: 2,
  CHARACTER: 1,
  NARRATOR: 2,
  SHOW_LEFT: 1,
  SHOW_RIGHT: 2,
  QUESTION_TYPE_1: 'Single Choice',
  QUESTION_TYPE_2: 'Arrange Words Into A Sentence'
}
