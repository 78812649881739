<template>
    <div v-if="levels">
        <h4>List Questions by Level</h4>
        <app-collapse type="shadow" class="mt-2 border p-1">
            <app-collapse-item v-for="(level, level_index) in levels" :key="level_index"
                class="parts-level"
            >
                <template slot="header">
                    <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">
                        <div class="d-flex align-items-center w-100 group-grab-button" style="cursor: grab;">
                        <feather-icon icon="TargetIcon" />
                        <span class="lead collapse-title ml-50 text-truncate d-block w-100" style="max-width: 90%">{{ LEVEL_NAME_BY_INDEX[level_index] }}</span>
                        </div>
                        <!-- <div style="position: absolute; right: 30px; ">
                        <b-button variant="flat-danger" class="btn-icon rounded-circle"
                            @click.stop="deleteLevel(level_index)"
                        >
                            <feather-icon icon="Trash2Icon" />
                        </b-button>
                        </div> -->
                    </div>
                </template>
                <span>Number of questions displayed in the Entry Test</span>
                <b-input v-model="level.question_number" />
                <div class="mt-2">
                    <h2>Question Bank</h2>  
                    <app-collapse v-if="part_type != 4" type="shadow" class="mt-2 border p-1">
                        <app-collapse-item v-for="(question, question_index) in level.questions" :key="'question'+question_index"
                            class="questions-level" :title="'Question '+ (question_index + 1)"
                        >
                            <template slot="header">
                                <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">
                                    <div class="d-flex align-items-center w-100 group-grab-button" style="cursor: grab;">
                                    <feather-icon icon="TargetIcon" />
                                    <span class="lead collapse-title ml-50 text-truncate d-block w-100" style="max-width: 90%">{{ 'Question '+ (question_index + 1) }}</span>
                                    </div>
                                    <div style="position: absolute; right: 30px; ">
                                    <b-button variant="flat-danger" class="btn-icon rounded-circle"
                                        @click.stop="deleteQuestion(level_index, question_index)"
                                    >
                                        <feather-icon icon="Trash2Icon" />
                                    </b-button>
                                    </div>
                                </div>
                            </template>
                            <div v-if="part_type == 1">
                                <!-- <strong class="h6">Game type</strong>
                                <b-form-select
                                    class="mb-2"
                                    v-model="question.game_type"
                                    :options="game_type_options"
                                /> -->
                                <strong class="h6">Countdown Timer (unit: second)</strong>
                                <b-form-input
                                    class="mb-2"
                                    v-model="question.match_duration"
                                />
                            </div>
                            <part-component :component_data="question" :index="`${part_index}_${level_index}_${question_index}`" :type="part_type" :options="{limit_words: 4}" />
                        </app-collapse-item>
                        
                        <div class="d-flex justify-content-center mt-1">
                            <b-button variant="flat-success"
                                @click="addQuestion(part_type, level_index)"
                            >
                            <feather-icon icon="PlusIcon" />
                            <span>Add Question</span>
                            </b-button>
                        </div>
                    </app-collapse>
                    <div v-else>
                        <pronunciation :componentData="level.questions" />
                    </div>
                </div>
            </app-collapse-item>
            <!-- <div class="d-flex justify-content-center mt-1">
                <b-button variant="flat-success"
                    @click="addLevel(part_index)"
                >
                <feather-icon icon="PlusIcon" />
                <span>Add Level</span>
                </b-button>
            </div> -->
        </app-collapse>
    </div> 
</template>
<script>
import PartComponent from './PartComponent.vue'
import { vocabDefault, listeningDefault, interactionDefault } from '../../constants';
import Pronunciation from './SpokenIntergration/Pronunciation.vue'

const LEVEL_NAME_BY_INDEX = {
    0: 'Beginner',
    1: 'Pre-intermediate',
    2: 'Intermediate',
}
export default {
    components:{
        PartComponent,
        Pronunciation
    },
    props: {
        levels: {type: Array, required: true},
        part_index: {type: Number, required: true},
        part_type: {type: Number, required: true},
    },
    data(){
        return {
            game_type_options:[
                {text: "Matching 1 Image & n Words", value: 1},
                {text: "Matching n-Images & n-Words", value: 2},
                {text: "Matching n-Images & 1-Word", value: 3}
            ],
            defaultQuestion: {
                1: vocabDefault,
                2: listeningDefault,
                3: interactionDefault,
            },
            LEVEL_NAME_BY_INDEX
        }
    },
    methods: {
        addLevel(){
            this.levels.push({
                question_number: 2,
                questions: []
            })
        },
		deleteLevel(level_index){
			this.levels.splice(level_index, 1)
		},
        addQuestion(part_type, level_index){
            this.levels[level_index].questions.push(JSON.parse(JSON.stringify(this.defaultQuestion[part_type])))
        },
        deleteQuestion(level_index, question_index){
            this.levels[level_index].questions.splice(question_index, 1)
        },
    }
}
</script>